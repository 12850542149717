import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import './css/calendar.css'
import moment from 'moment';
import { LoadingModal } from './component/modal/modal';
import { change_Current_Location, changeHeaderTitle } from '../store';

function SettlementCalendar() {
    let dispatch = useDispatch();
    const today = new Date();
    const [date, setDate] = useState(today);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleTodayClick = () => {
        const today = new Date();
        setActiveStartDate(today);
        setDate(today);
        setModal(true)
    };

    useEffect(() => {
        dispatch(change_Current_Location("정산달력"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "정산달력"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    return (
        <div className="content calendar" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <DetailInfo setModal={setModal} /> : ""}
            <Calendar
                value={date}
                onChange={handleDateChange}
                formatDay={(locale, date) => moment(date).format('D')}
                formatYear={(locale, date) => moment(date).format('YYYY')}
                formatMonthYear={(locale, date) => moment(date).format('YYYY. MM')}
                calendarType="gregory"
                showNeighboringMonth={false}
                next2Label={null}
                prev2Label={null}
                minDetail="year"

                // 오늘 날짜로 돌아오는 기능을 위해 필요한 옵션 설정
                activeStartDate={activeStartDate === null ? undefined : activeStartDate}
                onActiveStartDateChange={({ activeStartDate }) =>
                    setActiveStartDate(activeStartDate)
                }

                // 오늘 날짜에 '오늘' 텍스트 삽입하고 출석한 날짜에 점 표시를 위한 설정
                tileContent={({ date, view }) => {
                    let html = [];
                    if (view === "month") {
                        // for (let i = 0; i < totalData.length; i++) {
                        //     let tmp = totalData[i]
                        //     if (tmp.date.split('T')[0] === moment(date).format("YYYY-MM-DD")) {
                        //         html.push(<div className='StyledEX'>
                        //             <div className='StyledEX-1'>
                        //                 <div>Binance:</div>
                        //                 <div>{Number(tmp.binance_income).toFixed(7)} USDT</div>
                        //             </div>
                        //             <div className='StyledEX-2'>
                        //                 <div>OKX:</div>
                        //                 <div>{Number(tmp.okx_income).toFixed(7)} USDT</div>
                        //             </div>
                        //             <div className='StyledEX-3'>
                        //                 <div>Bybit:</div>
                        //                 <div>{Number(tmp.bybit_income).toFixed(7)} USDT</div>
                        //             </div>
                        //         </div>);
                        //     }
                        // }
                    }
                    return <>{html}</>;
                }}
            />

            {/* 오늘 버튼 추가 */}

            <ul className="calendar-category-wrap">
                <li className="calendar-category">
                    <span className="dot" style={{ background: "#eb5050" }}></span>
                    <p>정산완료</p>
                </li>
                <li className="calendar-category">
                    <span className="dot" style={{ background: "#1c658c" }}></span>
                    <p>정산예정</p>
                </li>
                <li className="calendar-category">
                    <span className="dot" style={{ background: "#064e3b" }}></span>
                    <p>당일 매출</p>
                </li>
                <div className='StyledDate' onClick={handleTodayClick}>Today</div>
            </ul>
        </div>
    )
}

function DetailInfo({ setModal }) {
    return (
        <div id="modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">상세내역</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body">
                            <div className="detail-form">
                                <table className="tbl-grid">
                                    <tbody>
                                        <tr className="border-btm">
                                            <th>정산일</th>
                                            <td data-id="SETTLEMENT_DATE"></td>
                                        </tr>
                                        <tr>
                                            <th>결제수단</th>
                                            <td>카드</td>
                                        </tr>
                                        <tr>
                                            <th>승인건수</th>
                                            <td><money data-id="CONFIRM_CNT">0</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>승인금액</th>
                                            <td><money data-id="CONFIRM_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소건수</th>
                                            <td><money data-id="CANCEL_CNT">0</money><span>건</span></td>
                                        </tr>
                                        <tr>
                                            <th>취소금액</th>
                                            <td><money data-id="CANCEL_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr style={{ display: "none" }}>
                                            <th>계좌이체</th>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                        </tr>
                                        <tr style={{ display: "none" }}>
                                            <th>가상계좌</th>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                            <td>0</td>
                                        </tr>
                                        <tr className="border-btm">
                                            <th>합계</th>
                                            <td><money data-id="TOTAL_AMT">0</money><span>원</span></td>
                                        </tr>
                                        <tr>
                                            <th>지급금액</th>
                                            <td><money data-id="SETTLEMENT_AMT">0</money><span>원</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 modal_btn" id="settlement_btn">정산</button>

                        <button type="button" className="btn btn-primary w-20 modal_btn" id="confirm_btn">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlementCalendar;