import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function UnsoldMerchant() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [loading, setLoading] = useState(true)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("무실적가맹점"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "무실적가맹점"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "180px", "textAlign": "center" },
        { "no": 4, "text": "가맹점명", "width": "200px", "textAlign": "left" },
        { "no": 5, "text": "가맹점주", "width": "180px", "textAlign": "left" },
        { "no": 6, "text": "구분", "width": "200px", "textAlign": "center" },
        { "no": 7, "text": "생성일", "width": "180px", "textAlign": "center" }
    ];

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        pickerRef.current?.setDateRange(startDate, endDate);
    };

    // 영업자 포맷
    const select1 = ["전체", "총판", "에이전시", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 검색구분 포맷
    const select4 = ["가맹점명", "TID"]

    const [selectedValue3, setSelectedValue3] = useState("가맹점명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/non-perform", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp.data?.inactiveFranchisees ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }
    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.id, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": data.tid, "width": "180px", "textAlign": "center" },
                    { "no": 4, "text": data.name, "width": "200px", "textAlign": "left" },
                    { "no": 5, "text": data.representativeName, "width": "180px", "textAlign": "left" },
                    { "no": 6, "text": "-", "width": "200px", "textAlign": "center" },
                    { "no": 7, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    const excelData = () => {
        let arr = []
        if (listData) {
            for (let i = 0; i < listData.length; i++) {
                let t = listData[i]

                arr.push({
                    "NO": "NO",
                    "TID": "TID",
                    "총판": "총판",
                    "에이전시": "에이전시",
                    "대리점": "대리점",
                    "가맹점": "가맹점",
                    "종류": "종류",
                    "상태": "상태",
                    "승인일자": "승인일자",
                    "취소일자": "취소일자",
                    "승인금액": "승인금액",
                    "승인번호": "승인번호",
                    "카드사": "카드사",
                    "할부개월": "할부개월",
                    "구분": "구분",
                    "결제정보": "결제정보",
                    "영수증": "영수증"
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">

                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => setReload(e => !e)} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <DownloadBtn text={"엑셀 다운로드"} onClick={excelData} />
                    <CountSelect onChange={handleChange} />
                </div>
                <div className="tabulator_summary_wrap">
                    <CountAmount tableBodyData={listData} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>

        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `List`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "TID", "총판", "에이전시", "대리점", "가맹점",
            "종류", "상태", "승인일자", "취소일자", "승인금액",
            "승인번호", "카드사", "할부개월", "구분", "결제정보", "영수증"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.종류 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.상태 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.취소일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인금액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.카드사 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.할부개월 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.결제정보 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.영수증 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default UnsoldMerchant