// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  width: 100%;
  position: relative;
  /* box-sizing: border-box; */
}

a{
  text-decoration: none;
}

/* .overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;;;GAIG","sourcesContent":[".App {\n  width: 100%;\n  position: relative;\n  /* box-sizing: border-box; */\n}\n\na{\n  text-decoration: none;\n}\n\n/* .overflow-hidden {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: block !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
