import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';


function ExcelData() {
    let dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)

    useEffect(() => {
        dispatch(change_Current_Location("업체등록"))
        dispatch(changeHeaderTitle({
            mainTitle: "매장관리",
            subTitle: "업체등록"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "이름", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "구분", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "주민등록번호", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "사업자구분", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "핸드폰", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "이메일", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "은행명", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "계좌번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "계좌주", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "메시지", "width": "300px", "textAlign": "center" }
    ];

    const tableBodyData = [
        { "no": 1, "text": "-", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "-", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "-", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "-", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 7, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "-", "width": "180px", "textAlign": "right" },
        { "no": 10, "text": "-", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "-", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "-", "width": "300px", "textAlign": "center" }
    ];

    const tableBodyData1 = [];

    for (let i = 0; i < 5; i++) {
        tableBodyData1.push(tableBodyData)
    }

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = tableBodyData1.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    return (
        <div class="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            <div class="intro-y box p-5 mt-5">
                <div class="intro-y box p-3">
                    <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
                        <div class="sm:flex items-center sm:mr-4">
                            <label class="btn btn-outline-secondary w-full sm:w-auto mr-2 agency_bt_sty btn_common" for="agency_excel">에이전시 업로드</label>
                            <input style={{ display: "none" }} class="btn btn-outline-secondary w-full sm:w-auto excel_upload_btn" id="agency_excel" aria-expanded="true" type="file" name="agencyExcel" />
                        </div>
                        <div class="sm:flex items-center sm:mr-4">
                            <label class="btn btn-outline-secondary w-full sm:w-auto mr-2 merchant_bt_sty btn_common" for="merchant_excel">가맹점 업로드</label>
                            <input style={{ display: "none" }} class="btn btn-outline-secondary w-full sm:w-auto excel_upload_btn" id="merchant_excel" aria-expanded="true" type="file" name="merchantExcel" />
                        </div>
                        <div class="sm:flex items-center sm:mr-4">
                            <label class="" for="addCnt">등록된 매장 수 : </label>
                            <input style={{ border: "0px" }} class="btn btn-outline-secondary" data-id="addCnt" id="addCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                            <label class="" for="updateCnt">수정된 매장 수 : </label>
                            <input style={{ border: "0px" }} class="btn btn-outline-secondary" data-id="updateCnt" id="updateCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                            <label class="" for="failCnt">실패한 매장 수 : </label>
                            <input style={{ border: "0px" }} class="btn btn-outline-secondary" data-id="last_page" id="failCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                        </div>
                    </div>
                </div>
                <div className="intro-y box p-5 mt-5">
                    <h2 style={{ fontSize: "1.2rem", paddingTop: "1rem" }} class="merchant_list_title">미등록 내역</h2>

                    <List tableHeaderData={tableHeaderData} tableBodyData={tableBodyData1} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                    <input type="hidden" id="fromDate" />
                    <input type="hidden" id="toDate" />
                    <input type="hidden" id="date_to_date" />
                </div>
                {/* <div class="overflow-x-auto scrollbar-hidden">
                    <h2 style="font-size: 1.2rem; padding-top: 1rem" class="merchant_list_title">미등록 내역</h2>
                    <div id="tabulator2" class="mt-3 table-report table-report--tabulator payList tabulator" role="grid" tabulator-layout="fitColumns">
                        <div class="tabulator-header" style="padding-right: 0px">
                            <div class="tabulator-headers" style="margin-left: 0px">
                                <div class="tabulator-col" role="columnheader" aria-sort="none" title="" style="min-width: 30px; width: 40px; display: none; height: 44px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder"><div class="tabulator-col-title">&nbsp;</div></div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="row" title="" style="min-width: 80px; width: 80px; height: 44px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: left">NO</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="NAME" title="" style="min-width: 120px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">이름</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="TID" title="" style="min-width: 60px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: left">TID</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BIZ_TYPE" title="" style="min-width: 60px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: left">구분</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BIZ_SOC_NO" title="" style="min-width: 180px; height: 44px; width: 180px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">주민등록번호</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BIZ_GUBUN" title="" style="min-width: 140px; height: 44px; width: 140px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">사업자구분</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BIZ_PHONE" title="" style="min-width: 100px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">핸드폰</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BIZ_EMAIL" title="" style="min-width: 150px; height: 44px; width: 150px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">이메일</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BANK_NAME" title="" style="min-width: 150px; height: 44px; width: 150px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: right">은행명</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="BANK_NO" title="" style="min-width: 80px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">계좌번호</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="HOLDER_NAME" title="" style="min-width: 80px; height: 44px; width: 134px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">계좌주</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                                <div class="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" tabulator-field="RESULT_STATUS_MESSAGE" title="" style="min-width: 80px; height: 44px; width: 144px">
                                    <div class="tabulator-col-content">
                                        <div class="tabulator-col-title-holder">
                                            <div class="tabulator-col-title" style="text-align: center">메시지</div>
                                            <div class="tabulator-col-sorter"><div class="tabulator-arrow"></div></div>
                                        </div>
                                    </div>
                                    <div class="tabulator-col-resize-handle"></div>
                                    <div class="tabulator-col-resize-handle prev"></div>
                                </div>
                            </div>
                            <div class="tabulator-frozen-rows-holder"></div>
                        </div>
                        <div class="tabulator-tableHolder" tabindex="0" style="min-height: calc(100% - 84px); height: calc(100% - 84px); max-height: calc(100% - 84px)">
                            <div class="tabulator-table" style="display: none"></div>
                            <div class="tabulator-placeholder" tabulator-render-mode="virtual" style="width: 1648px"><span>조회내역이 없습니다.</span></div>
                        </div>
                        <div class="tabulator-footer">
                            <span class="tabulator-paginator"
                            ><button class="tabulator-page" type="button" role="button" aria-label="First Page" title="First Page" data-page="first" disabled="">First</button><button class="tabulator-page" type="button" role="button" aria-label="Prev Page" title="Prev Page" data-page="prev" disabled="">Prev</button><span class="tabulator-pages"><button class="tabulator-page active" type="button" role="button" aria-label="Show Page 1" title="Show Page 1" data-page="1">1</button></span><button class="tabulator-page" type="button" role="button" aria-label="Next Page" title="Next Page" data-page="next" disabled="">Next</button><button class="tabulator-page" type="button" role="button" aria-label="Last Page" title="Last Page" data-page="last" disabled="">Last</button></span
                            >
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ExcelData;