import './App.css';
import './Pages/css/main.css'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Header from './Pages/nav/header';
import Footer from './Pages/nav/footer';
import Sidebar from './Pages/nav/sidebar';

import Login from './Pages/login';
import Dashboard from './Pages/dashboard';
import Paylist from './Pages/paylist';
import SettlementCalendar from './Pages/calendar';
import SetoffPaylist from './Pages/setoffPaylist';
import Settlements from './Pages/settlements';
import SetoffSettlements from './Pages/setoffSettlements';
import UnsoldMerchant from './Pages/unsoldMerchant';
import PayDetailList from './Pages/payDetailList';
import SettlementHis from './Pages/settlementHis';
import ExcelData from './Pages/excelData';
import Distributor from './Pages/distributor';
import Agency from './Pages/agency';
import Franchise from './Pages/franchise';
import Submerchants from './Pages/submerchants';
import Admin from './Pages/admin';
import Board from './Pages/board';
import Product from './Pages/product';

import ErrorBoundary from './Pages/common/ErrorBoundary'
import { useEffect, useState } from 'react';

function App() {
  console.error = (message) => {
    // if (message.startsWith('Warning: ...')) {
    //   return; // 특정 경고 무시
    // }
    // originalConsoleError(message);
  };

  let location = useLocation();

  return (
    <div className="App">
      <div className="py-5 md:py-0 header">
        <Header />
        <div className='section1'>
          <Sidebar />
          <div className={`section2 ${location.pathname == '/' ? "main" : ""}`}>
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/paylist" element={<Paylist />} />
                <Route path="/calendar" element={<SettlementCalendar />} />
                <Route path="/setoffPaylist" element={<SetoffPaylist />} />
                <Route path="/settlements" element={<Settlements />} />
                <Route path="/setoffSettlements" element={<SetoffSettlements />} />
                <Route path="/unsoldMerchant" element={<UnsoldMerchant />} />
                <Route path="/payDetailList" element={<PayDetailList />} />
                <Route path="/settlementHis" element={<SettlementHis />} />
                <Route path="/excelData" element={<ExcelData />} />
                <Route path="/distributor" element={<Distributor />} />
                <Route path="/agency" element={<Agency />} />
                <Route path="/franchise" element={<Franchise />} />
                <Route path="/submerchants" element={<Submerchants />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/board" element={<Board />} />
                <Route path="/product" element={<Product />} />

                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
