import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Settlements() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])
    const [loading, setLoading] = useState(true)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("정산내역"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "정산내역"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
    };
    // 영업자 포맷
    const select1 = ["전체", "총판", "에이전시", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 검색구분 포맷
    const select4 = ["매장명", "TID", "승인번호"]

    const [selectedValue3, setSelectedValue3] = useState("매장명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                salesCategory: selectedValue == "총판" ? 1 : selectedValue == "에이전시" ? 2 : selectedValue == "대리점" ? 3 : 0,
                salesValue: selectedText,
                searchCategory: selectedValue3 == "매장명" ? 1 : selectedValue3 == "TID" ? 2 : selectedValue3 == "승인번호" ? 3 : 0,
                searchValue: selectedText3,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                console.log(tmp.data)
                setListData(tmp.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "120px", "textAlign": "left" },
        { "no": 4, "text": "총판", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "에이전시", "width": "180px", "textAlign": "left" },
        { "no": 6, "text": "대리점", "width": "180px", "textAlign": "left" },
        { "no": 7, "text": "가맹점명", "width": "180px", "textAlign": "left" },
        { "no": 8, "text": "통신비", "width": "100px", "textAlign": "center" },
        { "no": 9, "text": "가맹점 계좌번호", "width": "180px", "textAlign": "left" },
        { "no": 10, "text": "구분", "width": "120px", "textAlign": "center" },
        { "no": 11, "text": "매출액", "width": "120px", "textAlign": "right" },
        { "no": 12, "text": "앤트", "width": "100px", "textAlign": "right" },
        { "no": 13, "text": "총판", "width": "100px", "textAlign": "right" },
        { "no": 14, "text": "에이전시", "width": "100px", "textAlign": "right" },
        { "no": 15, "text": "대리점", "width": "120px", "textAlign": "right" },
        { "no": 16, "text": "정산금액", "width": "120px", "textAlign": "right" },
        { "no": 17, "text": "정산일자", "width": "180px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.settlements ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.id, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": data.tid, "width": "120px", "textAlign": "left" },
                    { "no": 4, "text": data.tier1Name, "width": "180px", "textAlign": "left" },
                    { "no": 5, "text": data.tier2Name, "width": "180px", "textAlign": "left" },
                    { "no": 6, "text": data.tier3Name, "width": "180px", "textAlign": "left" },
                    { "no": 7, "text": data.franchiseeName, "width": "180px", "textAlign": "left" },
                    { "no": 8, "text": "N", "width": "100px", "textAlign": "center" },
                    { "no": 9, "text": "1002-955-978944", "width": "180px", "textAlign": "left" },
                    { "no": 10, "text": "비사업자PG", "width": "120px", "textAlign": "center" },
                    { "no": 11, "text": "1,600,000", "width": "120px", "textAlign": "right" },
                    { "no": 12, "text": "93,760", "width": "100px", "textAlign": "right" },
                    { "no": 13, "text": "48,000", "width": "100px", "textAlign": "right" },
                    { "no": 14, "text": "0", "width": "100px", "textAlign": "right" },
                    { "no": 15, "text": "0", "width": "120px", "textAlign": "right" },
                    { "no": 16, "text": "1,488,000", "width": "120px", "textAlign": "right" },
                    { "no": 17, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])


    const excelListBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/list/excel", {
            withCredentials: true,
            params: {
                salesCategory: "",
                salesValue: "",
                creditCategory: "",
                searchCategory: "",
                searchValue: "",
                startDate: "",
                endDate: "",
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelListData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelListData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createdAt.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id,
                    "TID": t.tid,
                    "총판": t.tier1Name,
                    "에이전시": t.tier2Name,
                    "대리점": t.tier3Name,
                    "가맹점명": t.franchiseeName,
                    "통신비": "N",
                    "가맹점계좌번호": "1002-955-978944",
                    "구분": "비사업자PG",
                    "매출액": "1,600,000",
                    "앤트": "93,760",
                    "총판": "48,000",
                    "에이전시": "0",
                    "대리점": "0",
                    "정산금액": "1,488,000",
                    "정산일자": `${date[0]} ${date1[0]}`,
                })
            }
        }
        Excel(1, arr)
    }

    const excelDepositBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/deposit/list", {
            withCredentials: true,
            params: {
                startDate: "20240913",
                endDate: "20240913",
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelDepositData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelDepositData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                arr.push({
                    "은행": t.bankName,
                    "계좌번호": t.accountNumber,
                    "금액": t.amount,
                    "가맹점": t.anme,
                    "지급사": t.send,
                })
            }
        }
        Excel(2, arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">

                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => setReload(e => !e)} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <div className='flex gap-4'>
                        <DownloadBtn text={"엑셀 다운로드"} onClick={excelListBtn} />
                        <DownloadBtn text={"입금 엑셀"} onClick={excelDepositBtn} />
                    </div>
                    <CountSelect onChange={handleChange} />
                </div>
                <div className="tabulator_summary_wrap">
                    <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                    <CountAmount tableBodyData={listData?.meta?.total ?? 0} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.meta?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>

        </div>
    )
}

const Excel = (num, excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `정산내역`;

    const ws = XLSX.utils.aoa_to_sheet([
        num == 1
            ?
            [
                "NO", "MCODE", "TID", "총판", "에이전시", "대리점", "가맹점명", "통신비", "가맹점 계좌번호",
                "구분", "매출액", "앤트", "총판", "에이전시", "대리점", "정산금액", "정산일자"
            ]
            :
            [
                "은행", "계좌번호", "금액", "가맹점", "지급사"
            ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                num == 1
                    ?
                    [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점명 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.통신비 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점계좌번호 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.매출액 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.앤트 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.정산금액 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.정산일자 }
                    ]
                    :
                    [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.은행 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.계좌번호 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.금액 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.지급사 },
                    ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            num == 1
                ?
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 }]
                :
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                ]
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Settlements