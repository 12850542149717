import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { SquareX } from 'lucide-react';

import { CountAmount, CountAmountLeft, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Board() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [loading, setLoading] = useState(true)

    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const [message, setMessage] = useState("")
    const [boardData, setBoardData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])

    useEffect(() => {
        dispatch(change_Current_Location("공지사항"))
        dispatch(changeHeaderTitle({
            mainTitle: "관리자 메뉴",
            subTitle: "공지사항"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    const BoardData = async () => {
        await axios.get(process.env.REACT_APP_API + "/notice/list", {
            withCredentials: true,
            params: {

            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setBoardData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "제목", "width": "300px", "textAlign": "center" },
        { "no": 3, "text": "내용", "width": "200px", "textAlign": "center" },
        { "no": 4, "text": "구분", "width": "150px", "textAlign": "center" },
        { "no": 5, "text": "관리", "width": "150px", "textAlign": "center" },
        { "no": 6, "text": "등록일자", "width": "180px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (boardData) {
            for (let i = 0; i < boardData.length; i++) {
                let data = boardData[i]

                let date = data.createDate.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    { "no": 1, "text": i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": "2024년 9월 무이자 할부200282", "width": "300px", "textAlign": "center" },
                    { "no": 3, "text": "", "width": "200px", "textAlign": "center" },
                    { "no": 4, "text": "가맹점", "width": "150px", "textAlign": "center" },
                    {
                        "no": 5, "text": (
                            <div className="flex lg:justify-center items-center">
                                <a className="delete flex items-center text-danger" href="javascript:;">
                                    <SquareX width={15} height={15} /> 삭제
                                </a>
                            </div>), "width": "150px", "textAlign": "center"
                    },
                    { "no": 6, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [boardData])

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = combineData.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    const excelData = () => {
        let arr = []
        if (combineData) {
            for (let i = 0; i < combineData.length; i++) {
                let t = combineData[i]

                arr.push({
                    "NO": "NO",
                    "TID": "TID",
                    "총판": "총판",
                    "에이전시": "에이전시",
                    "대리점": "대리점",
                    "가맹점": "가맹점",
                    "종류": "종류",
                    "상태": "상태",
                    "승인일자": "승인일자",
                    "취소일자": "취소일자",
                    "승인금액": "승인금액",
                    "승인번호": "승인번호",
                    "카드사": "카드사",
                    "할부개월": "할부개월",
                    "구분": "구분",
                    "결제정보": "결제정보",
                    "영수증": "영수증"
                })
            }
        }
        Excel(arr)
    }

    // 검색어 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    // 검색 버튼
    const submit = () => {
        console.log('1')
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {createModal ? <CreateInfo setModal={setCreateModal} setMessage={setMessage} /> : ""}
            {updateModal ? <UpdateInfo setModal={setUpdateModal} setMessage={setMessage} clickData={clickData} /> : ""}
            <MessageModal message={message} />
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    {<SearchInputBtn type={""} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} onClick={submit} />}

                    <div className="button_wrap separate" style={{ marginTop: "0" }}>
                        <SearchCrteateBtn type={"공지등록"} onClick={() => setCreateModal(true)} />
                    </div>
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <CountAmountLeft tableBodyData={combineData} />
                    <CountSelect onChange={handleChange} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={combineData} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>
        </div>
    )
}

function CreateInfo({ setModal, setMessage }) {
    const [formData, setFormData] = useState({
        popup_yn: false,
        mer_level_yn: false,
        quota_yn: false,
        TITLE: '',
        CONTENTS: '',
        BOARD_IMG: null,
    });
    const [imagePreviews, setImagePreviews] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else if (type === 'file') {
            setFormData({ ...formData, BOARD_IMG: files });
            handleImagePreview(files);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // 이미지 미리보기 처리 함수
    const handleImagePreview = (files) => {
        const previewArray = Array.from(files).map((file) => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result); // 파일의 base64 데이터를 resolve
                };
                reader.readAsDataURL(file); // 파일을 base64로 변환
            });
        });

        Promise.all(previewArray).then((images) => {
            setImagePreviews(images); // 미리보기 이미지들을 저장
        });
    };

    const onSubmit = async () => {
        const formDataObj = new FormData();

        formDataObj.append('title', formData.TITLE);
        formDataObj.append('content', formData.CONTENTS);
        formDataObj.append('isFranchiseNotice', formData.mer_level_yn ? 1 : 0);
        formDataObj.append('isPopupVisible', formData.popup_yn ? 1 : 0);
        formDataObj.append('isSugiVisible', formData.quota_yn ? 1 : 0);
        formDataObj.append(`file`, formData?.BOARD_IMG[0] ?? "");

        await axios.post(process.env.REACT_APP_API + "/notice/create", formDataObj, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {

            }
            else {
                setMessage({ msg: "등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div id="board-modal" className="modal" tabindex="-1">
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">공지사항 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_close_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown">
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg> </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>

                    <form id="board-modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3" encType="multipart/form-data">
                        <div className="col-span-12 sm:col-span-6" style={{ display: "block" }}>
                            <label htmlFor="popup_yn" className="form-label">팝업 노출 여부</label><br />
                            <label className="switch">
                                <input type="checkbox" id="popup_yn" name="popup_yn" checked={formData.popup_yn} onChange={handleChange} />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="col-span-12 sm:col-span-6" style={{ display: "block" }}>
                            <label htmlFor="mer_level_yn" className="form-label">가맹점 공지여부</label><br />
                            <label className="switch">
                                <input type="checkbox" id="mer_level_yn" name="mer_level_yn" checked={formData.mer_level_yn} onChange={handleChange} />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        <div className="col-span-12 sm:col-span-12" style={{ display: "block" }}>
                            <label htmlFor="quota_yn" className="form-label">수기결제 할부 노출 여부</label><br />
                            <label className="switch">
                                <input type="checkbox" id="quota_yn" name="quota_yn" checked={formData.quota_yn} onChange={handleChange} />
                                <span className="slider round"></span>
                            </label>
                        </div>

                        <input type="hidden" id="event_check" />
                        <input type="hidden" id="FILE_NAME" name="FILE_NAME" />

                        <div className="hidden">
                            <input id="IDX" name="IDX" type="text" className="form-control" data-id="IDX" />
                        </div>

                        <div className="col-span-12 sm:col-span-6 ">
                            <label htmlFor="TITLE" className="form-label">제목</label>
                            <input name="TITLE" id="TITLE" type="text" className="form-control" value={formData.TITLE} onChange={handleChange} />
                        </div>

                        <div className="col-span-12 sm:col-span-12">
                            <h1 className="form-label" style={{ marginBottom: "0.375rem", fontSize: ".8125rem", fontWeight: "bold" }}>첨부 파일(이미지)</h1>

                            {/* 파일 선택 라벨에 이미지 이름 표시 */}
                            <label htmlFor="BOARD_IMG" className="form-control file_form" style={{ display: "block" }}>
                                {formData.BOARD_IMG ? Array.from(formData.BOARD_IMG).map(file => file.name).join(', ') : "파일선택"}
                            </label>

                            <input type="file" id="BOARD_IMG" name="BOARD_IMG" style={{ display: "none" }} multiple accept="image/*" onChange={handleChange} />

                            <div id="previewContainer" className="mt-4">
                                {imagePreviews.length > 0 &&
                                    imagePreviews.map((src, index) => (
                                        <img key={index} src={src} alt={`preview ${index}`} className="multipreview" style={{ width: '100px', marginRight: '10px' }} />
                                    ))}
                            </div>
                        </div>


                        <div className="col-span-12">
                            <label htmlFor="CONTENTS" className="form-label">내용</label>
                            <textarea id="CONTENTS" name="CONTENTS" className="form-control h-40 resize-none" value={formData.CONTENTS} onChange={handleChange}></textarea>
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="button" className="btn w-20 modal_btn btn-danger modify_btn" onClick={() => onSubmit()}>등록</button>
                        <button type="button" className="btn w-20 modal_close_btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div >
        </div >
    )
}

function UpdateInfo({ setModal, setMessage, clickData }) {
    const [signupId, setSignupId] = useState(clickData.id);
    const [signupName, setSignupName] = useState(clickData.name);
    const [signupEmail, setSignupEmail] = useState(clickData.email);
    const [signupPw, setSignupPw] = useState("1234556");
    const [signupPhone, setSignupPhone] = useState(clickData.mobile);
    const [merchantGrade, setMerchantGrade] = useState(clickData.roleId == 1 ? "SUPER" : "NORMAL");

    const onSubmit = async () => {
        if (signupName != '' && signupEmail != '' && signupPw != '' && signupPhone != '') {
            onUpdateLevel()
            onUpdateInfo()
            setModal(false)
            // onUpdatePW()
        }
        else {
            setMessage({ msg: "다시 입력해주세요." })
        }
    }

    const onUpdateLevel = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-role", {
            id: signupId,
            roleId: merchantGrade == "SUPER" ? 1 : 2
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }
    const onUpdateInfo = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-contact", {
            id: signupId,
            email: signupEmail,
            mobile: signupPhone,
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }
    const onUpdatePW = async () => {
        await axios.post(process.env.REACT_APP_API + "/admin/update-password", {
            id: signupId,
            oldPassword: "",
            newPassword: signupPw,
        }, {
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {
            }
            else {
                setMessage({ msg: "관리자 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div id="admin-modal" className="modal show_modal" tabindex="-1">
            <input type="hidden" id="event_check" value="update" />
            <div className="modal-dialog">
                <div className="modal-content marginTop30 admin-modal-sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto">관리자 정보</h2>
                        <div id="data_wrap">
                            <input type="hidden" id="m_code" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12">
                            <label htmlFor="signup-id-merchant" className="form-label">가맹점</label>
                            <input
                                id="signup-id-merchant"
                                type="text"
                                className="form-control"
                                placeholder=""
                                disabled="true"
                                value={signupName}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-id" className="form-label">아이디</label>
                            <input
                                id="signup-id"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupEmail}
                                onChange={(e) => setSignupEmail(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-pw" className="form-label">패스워드</label>
                            <input
                                id="signup-pw"
                                type="password"
                                autoComplete="off"
                                className="form-control"
                                placeholder=""
                                disabled="true"
                                value={signupPw}
                                onChange={(e) => setSignupPw(e.target.value)}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="signup-phone" className="form-label">연락처(번호)</label>
                            <input
                                id="signup-phone"
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={signupPhone}
                                onChange={(e) => setSignupPhone(e.target.value)}
                            />
                        </div>

                        <div id="merchant-list-wrap" className="col-span-12">
                            <label htmlFor="merchant-list" className="form-label">등급</label>
                            <div className="merchant-list-box">
                                <select
                                    id="merchant-list"
                                    className="form-select form-select-sm mt-2"
                                    value={merchantGrade}
                                    onChange={(e) => setMerchantGrade(e.target.value)}
                                >
                                    <option value="SUPER">SUPER</option>
                                    <option value="NORMAL">NORMAL</option>
                                </select>
                            </div>
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button id="signup-btn" type="submit" className="btn btn-primary" onClick={() => onSubmit()}>등록</button>
                        <button type="button" data-tw-dismiss="modal" className="btn btn-outline-secondary mr-1 admin-modal-btn" onClick={() => setModal(false)}>닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `List`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "TID", "총판", "에이전시", "대리점", "가맹점",
            "종류", "상태", "승인일자", "취소일자", "승인금액",
            "승인번호", "카드사", "할부개월", "구분", "결제정보", "영수증"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.종류 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.상태 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.취소일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인금액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.카드사 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.할부개월 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.결제정보 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.영수증 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Board