const removeZeros = (num) => {
    let strNum = num.toString();
    strNum = strNum.replace(/(\.\d*?)0+$/, "$1");
    strNum = strNum.replace(/\.$/, "");

    return Decimalization(strNum);
}

const Decimalization = (n) => {
    let str = String(n).toUpperCase()
    let arr = str.split('E-')
    let result;
    if (arr[1]) {
        result = (Number(arr[0]) / Math.pow(10, Number(arr[1]))).toFixed(Number(arr[1]) + arr[0].length - 2)
    }
    else {
        result = arr[0]
    }

    return result
}

const changeNum = (n) => {
    let num = String(n)
    let arr = num.split('.')
    let result;

    if (arr[1]) {
        result = Number(arr[0]).toLocaleString() + "." + arr[1]
    }
    else {
        result = Number(arr[0]).toLocaleString()
    }

    return result;
}

export { removeZeros, Decimalization, changeNum }