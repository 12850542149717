import '../css/common.css'
import '../css/app.css'
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom"

function Footer(){
    return(
        <div>

        </div>
    )
}

export default Footer;