import React, { Component } from 'react';
import '../css/main.css'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <div className='error-modal' style={{ backdropFilter: "blur(3px)" }}></div>
                    <div className={`message-modal`}>
                        <div className='message-modal-2' style={{ paddingBottom: "30px" }}>The data could not be loaded,<br /> So please reload.</div>
                        <div className='message-modal-btn' onClick={() => { window.location.reload() }}>Reload</div>
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;