import { configureStore, createSlice } from '@reduxjs/toolkit'

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let SelectType = createSlice({
    name: 'SelectType',
    initialState: { value: 0 },
    reducers: {
        checkSelectType(state, action) {
            let s = action.payload
            if (s == "SUPER") state.value = 0
            else if (s == "MANAGER") state.value = 1
            else state.value = 2
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: false },
    reducers: {
        checkMode(state, action) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                const newobj = {
                    value: obj.value,
                    expire: obj.expire,
                    mode: action.payload ? "dark" : "light",
                }
                const newobjString = JSON.stringify(newobj);
                localStorage.setItem('token', newobjString)
            }
            state.value = action.payload
        }
    }
})

let HeaderTitle = createSlice({
    name: 'HeaderTitle',
    initialState: {
        mainTitle: "",
        subTitle: ""
    },
    reducers: {
        changeHeaderTitle(state, action) {
            return action.payload
        }
    }
})

let Current_Location = createSlice({
    name: 'Current_Location',
    initialState: { value: "" },
    reducers: {
        change_Current_Location(state, action) {
            state.value = action.payload
        }
    }
})


export let { checkLogin } = login.actions
export let { checkSelectType } = SelectType.actions
export let { checkMode } = Darkmode.actions
export let { changeHeaderTitle } = HeaderTitle.actions
export let { change_Current_Location } = Current_Location.actions

export default configureStore({
    reducer: {
        login: login.reducer,
        SelectType: SelectType.reducer,
        Darkmode: Darkmode.reducer,
        HeaderTitle: HeaderTitle.reducer,
        Current_Location: Current_Location.reducer,
    }
}) 