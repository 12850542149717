import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Paylist() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("거래내역"))
        dispatch(changeHeaderTitle({
            mainTitle: "거래조회",
            subTitle: "거래내역 조회"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
    };

    // 영업자 포맷
    const select1 = ["전체", "총판", "에이전시", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 결제상태 포맷
    const select2 = ["결제상태", "승인", "취소"]

    const [selectedValue1, setSelectedValue1] = useState("결제상태");

    const handleSelectChange1 = (event) => {
        const value = event.target.value;
        setSelectedValue1(value);
    };

    // 금액범위 포맷
    const [preRange, setPreRange] = useState("")
    const [nextRange, setNextRange] = useState("")

    const handlePreRange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // 숫자만 허용 (정규식을 이용하여 숫자 여부 체크)
            setPreRange(value);
        }
    };

    const handleNextRange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) { // 숫자만 허용
            setNextRange(value);
        }
    };

    // PG구분 포맷
    const select3 = ["PG 구분", "웰컴", "코페이", "광원"]

    const [selectedValue2, setSelectedValue2] = useState("PG 구분");

    const handleSelectChange2 = (event) => {
        const value = event.target.value;
        setSelectedValue2(value);
    };

    // 검색구분 포맷
    const select4 = ["매장명", "TID", "승인번호"]

    const [selectedValue3, setSelectedValue3] = useState("매장명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/transaction/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                salesCategory: selectedValue == "총판" ? 1 : selectedValue == "에이전시" ? 2 : selectedValue == "대리점" ? 3 : 0,
                salesValue: selectedText,
                creditCategory: selectedValue1 == "승인" ? 1 : selectedValue1 == "취소" ? 2 : 0,
                amountStart: preRange,
                amountEnd: nextRange,
                businessType: selectedValue2 == "PG 구분" ? "" : selectedValue2,
                searchCategory: selectedValue3 == "매장명" ? 1 : selectedValue3 == "TID" ? 2 : selectedValue3 == "승인번호" ? 3 : 0,
                searchValue: selectedText3,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "80px", "textAlign": "left" },
        { "no": 2, "text": "TID", "width": "120px", "textAlign": "center" },
        { "no": 3, "text": "총판", "width": "180px", "textAlign": "left" },
        { "no": 4, "text": "에이전시", "width": "180px", "textAlign": "left" },
        { "no": 5, "text": "대리점", "width": "180px", "textAlign": "left" },
        { "no": 6, "text": "가맹점", "width": "180px", "textAlign": "left" },
        { "no": 7, "text": "종류", "width": "150px", "textAlign": "center" },
        { "no": 8, "text": "상태", "width": "80px", "textAlign": "center" },
        { "no": 9, "text": "승인일자", "width": "180px", "textAlign": "center" },
        { "no": 10, "text": "취소일자", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "승인금액", "width": "150px", "textAlign": "center" },
        { "no": 12, "text": "승인번호", "width": "150px", "textAlign": "center" },
        { "no": 13, "text": "카드사", "width": "200px", "textAlign": "center" },
        { "no": 14, "text": "할부개월", "width": "100px", "textAlign": "center" },
        { "no": 15, "text": "구분", "width": "120px", "textAlign": "left" },
        { "no": 16, "text": "결제정보", "width": "100px", "textAlign": "center" },
        { "no": 17, "text": "영수증", "width": "100px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                let date2 = data.updatedAt.split('T')
                let date3 = date2[1].split('.')


                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "80px", "textAlign": "left" },
                    { "no": 2, "text": data.catId, "width": "120px", "textAlign": "center" },
                    { "no": 3, "text": data.salesHierarchy.find(item => item.level === 1)?.name ?? "", "width": "180px", "textAlign": "left" },
                    { "no": 4, "text": data.salesHierarchy.find(item => item.level === 2)?.name ?? "", "width": "180px", "textAlign": "left" },
                    { "no": 5, "text": data.salesHierarchy.find(item => item.level === 3)?.name ?? "", "width": "180px", "textAlign": "left" },
                    { "no": 6, "text": data.franchisee.name, "width": "180px", "textAlign": "left" },
                    { "no": 7, "text": data.cardAuthNo, "width": "150px", "textAlign": "center" },
                    { "no": 8, "text": "승인", "width": "80px", "textAlign": "center" },
                    { "no": 9, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" },
                    { "no": 10, "text": `${date2[0]} ${date3[0]}`, "width": "180px", "textAlign": "center" },
                    { "no": 11, "text": data.amount, "width": "150px", "textAlign": "center" },
                    { "no": 12, "text": data.cpId, "width": "150px", "textAlign": "center" },
                    { "no": 13, "text": data.cardName, "width": "200px", "textAlign": "center" },
                    { "no": 14, "text": `${data.quota}개월`, "width": "100px", "textAlign": "center" },
                    { "no": 15, "text": "비사업자PG", "width": "120px", "textAlign": "left" },
                    {
                        "no": 16,
                        "text": (
                            <div className='pointer' onClick={() => { setClickData(data); setModal(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-archive w-4 h-4" style={{ color: "#0064ff" }}>
                                    <path d="M20 9v9a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9m16-5H4a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm-10 9h4"></path>
                                </svg>
                            </div>
                        ),
                        "width": "100px",
                        "textAlign": "center"
                    },
                    {
                        "no": 17,
                        "text": (
                            <div className='pointer' onClick={() => { setClickData(data); setModal1(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-clipboard w-4 h-4" style={{ color: "#0a6e0a" }}>
                                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                </svg>
                            </div>
                        ),
                        "width": "100px",
                        "textAlign": "center"
                    }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])


    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/transaction/list/excel", {
            withCredentials: true,
            params: {
                salesCategory: "",
                salesValue: "",
                creditCategory: "",
                amountStart: "",
                amountEnd: "",
                businessType: "",
                searchCategory: "",
                searchValue: "",
                startDate: "",
                endDate: "",
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createdAt.split('T')
                let date1 = date[1].split('.')

                let date2 = t.updatedAt.split('T')
                let date3 = date2[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "TID": t.catId,
                    "총판": t.salesHierarchy.find(item => item.level === 1)?.name ?? "",
                    "에이전시": t.salesHierarchy.find(item => item.level === 2)?.name ?? "",
                    "대리점": t.salesHierarchy.find(item => item.level === 3)?.name ?? "",
                    "가맹점": t.franchisee.name,
                    "종류": t.catId,
                    "상태": t.cardAuthNo,
                    "승인일자": `${date[0]} ${date1[0]}`,
                    "취소일자": `${date2[0]} ${date3[0]}`,
                    "승인금액": t.amount,
                    "승인번호": t.cpId,
                    "카드사": t.cardName,
                    "할부개월": `${t.quota}개월`,
                    "구분": "비사업자PG",
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {modal ? <PayInfo setModal={setModal} /> : ""}
            {modal1 ? <ReceiptInfo setModal={setModal1} /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />}


                    {moreBtn && <SearchSelect type={"결제상태"} selectData={select2} handleSelectChange={handleSelectChange1} selectedValue={selectedValue1} />}

                    <SearchRange type={"금액범위"} preRange={preRange} handlePreRange={handlePreRange} nextRange={nextRange} handleNextRange={handleNextRange} />

                    {moreBtn && <SearchSelect type={"PG 구분"} selectData={select3} handleSelectChange={handleSelectChange2} selectedValue={selectedValue2} />}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => setReload(e => !e)} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <DownloadBtn text={"엑셀 다운로드"} onClick={excelBtn} />
                    <CountSelect onChange={handleChange} />
                </div>
                <div className="tabulator_summary_wrap">
                    <TotalAmount confirm_amt={amount[0]} canceled_amt={amount[1]} />
                    <CountAmount tableBodyData={listData?.total ?? 0} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>
        </div>
    )
}

function PayInfo({ setModal }) {
    return (
        <div id="payment-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">결제 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                            <input type="hidden" id="event_check" />
                            <div className="col-span-12 sm:col-span-6">
                                <label for="CARD_NAME" className="form-label">결제카드</label>
                                <input id="CARD_NAME" type="text" className="form-control" readonly="" data-id="CARD_NAME" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="LEFT_AMT" className="form-label">금액</label>
                                <input id="LEFT_AMT" type="text" className="form-control" readonly="" data-id="CONFIRM_AMT" />
                            </div>
                            <div className="col-span-12">
                                <label for="TID" className="form-label">거래 아이디</label>
                                <input id="TID" type="text" className="form-control" readonly="" data-id="TID" />
                            </div>
                            <div className="col-span-12">
                                <label for="PRODUCT_NAME" className="form-label">상품명</label>
                                <input id="PRODUCT_NAME" type="text" className="form-control" readonly="" data-id="PRODUCT_NAME" />
                            </div>
                            <div className="col-span-12">
                                <label for="ORDER_NO" className="form-label">결제번호</label>
                                <input id="ORDER_NO" type="text" className="form-control" readonly="" data-id="ORDER_NO" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="MODAL_MID" className="form-label">가맹점 아이디</label>
                                <input id="MODAL_MID" type="text" className="form-control" readonly="" data-id="MID" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="RECEIPT_NO" className="form-label">영수증 번호</label>
                                <input id="RECEIPT_NO" type="text" className="form-control" readonly="" data-id="RECEIPT_NO" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="REGDATE" className="form-label">날짜</label>
                                <input id="REGDATE" type="text" className="form-control" readonly="" data-id="CONFIRM_DATE" />
                            </div>

                            <div className="col-span-12 sm:col-span-6">
                                <label for="TRADE_STATUS_NAME" className="form-label">거래 상태</label>
                                <input id="TRADE_STATUS_NAME" type="text" className="form-control" readonly="" data-id="TRADE_STATUS_NAME" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="USER_NAME" className="form-label">이름</label>
                                <input id="USER_NAME" type="text" className="form-control" readonly="" data-id="USER_NAME" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="USER_NO" className="form-label">결제 구분</label>
                                <input id="USER_NO" type="text" className="form-control" readonly="" data-id="USER_NO" />
                            </div>
                            <div className="col-span-12 sm:col-span-6">
                                <label for="CANCELMSG" className="form-label CANCELMSG">구분</label>
                                <select id="CANCELMSG" className="form-select w-full 2xl:w-full mt-2 sm:mt-0 CANCELMSG">
                                    <option value="일반취소">일반 취소</option>
                                </select>
                            </div>
                            <input id="MOID" type="hidden" className="form-control" readonly="" data-id="MOID" />
                            <input id="mcode" type="hidden" className="form-control" readonly="" data-id="mcode" />
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button type="button" className="btn w-20 modal_btn btn-danger cancel_btn">취소</button>
                        <button type="button" className="btn w-20 modal_btn close_btn">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ReceiptInfo({ setModal }) {
    return (
        <div id="receipt-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty type-receipt receipt-modal-content">
                    <div className="modal-header receipt-modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">결제 정보</h2>
                        <button className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form">
                        <div className="modal-body receipt-modal-body">
                            <input type="hidden" id="event_check" />
                            <div className="box-sales" id="box-sales">
                                <div className="box-result" style={{ top: "50%", position: "absolute" }}>
                                    <div className="inner">
                                        <img src="/img/bg_cancel.png" alt="취소" />
                                    </div>
                                </div>
                                <div id="header">
                                    <h2>신용카드<br />매출전표</h2>
                                    <h1><img src="/img/ant_gray.png" alt="앤트페이" /></h1>
                                </div>
                                <div className="list-info-box">
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">카드번호</span>
                                                        <span className="right-text" data-id="CARD_NO"></span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">카드종류</span>
                                                        <span className="right-text" data-id="CARD_NAME">KB국민카드</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래구분</span>
                                                        <span className="right-text" data-id="STATE_CD">승인</span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">할부개월</span>
                                                        <span className="right-text" data-id="CARD_SELL_MON">일시불</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">거래일시</span>
                                                        <span className="right-text" data-id="APP_DT" id="APP_DT">2022/06/16 14:37:58</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">영수증번호</span>
                                                        <span className="right-text" data-id="RECEIPT_NO"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap type-2">
                                        <div className="gap">
                                            <div className="tt"><h3>구매자정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">구매자</span>
                                                        <span className="right-text" data-id="USER_NAME">-</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">승인번호</span>
                                                        <span className="right-text" id="APP_NO" data-id="APP_NO"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">주문번호</span>
                                                        <span className="right-text" data-id="ORDER_NO">20220616143758193301</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상품명</span>
                                                        <span className="right-text" data-id="PRODUCT_NAME">곰인형</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gap">
                                            <div className="tt"><h3>결제금액정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">과세금액</span>
                                                        <span className="right-text" id="PG_AMT_BEFORE"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">부가세</span>
                                                        <span className="right-text" id="PG_VAT_AMT"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">면세금액</span>
                                                        <span className="right-text">0</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">합계</span>
                                                        <span className="right-text" data-id="AMOUNT"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>이용상점정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">상점명</span>
                                                        <span className="right-text" id="MER_NAME" data-id="MER_NAME"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">URL</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">이용문의<br /><span className="txt-s">(구매/취소/배송)</span></span>
                                                        <span className="right-text" id="MER_HP" data-id="MER_HP"></span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">대표자</span>
                                                        <span className="right-text" id="MER_CEO" data-id="MER_CEO"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" id="MER_BIZ_ADDRESS" data-id="MER_BIZ_ADDRESS"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="box-gap">
                                        <div className="gap">
                                            <div className="tt"><h3>결제서비스업체정보</h3></div>
                                            <ul>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">결제대행사명</span>
                                                        <span className="right-text" data-id="NAME"></span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">사업자번호</span>
                                                        <span className="right-text" data-id="COMP_NO"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner">
                                                        <span className="left-text">대표자명</span>
                                                        <span className="right-text" data-id="CEO"></span>
                                                    </div>
                                                    <div className="inner">
                                                        <span className="left-text">가맹점번호</span>
                                                        <span className="right-text"></span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="inner" style={{ width: "100%" }}>
                                                        <span className="left-text">사업장주소</span>
                                                        <span className="right-text" data-id="ADDR"></span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="infoFooter">
                                    부가가치세법 제46조 3항에 따라 신용카드 매출전표도 매입세금계산서로 사용할 수 있으며, 부가가치세법 제33조 2항에 따라 별도의 세금계산서를 교부하지 않습니다.
                                    <div className="box-btn">
                                    </div>
                                </div>
                            </div>
                            <input id="MOID" type="hidden" className="form-control" readonly="" />
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button id="receipt_print_btn" type="button" className="btn" style={{ marginRight: "3px", backgroundColor: "#d2e4fb", fontWeight: "600" }}>저장</button>
                        <button type="button" className="btn w-20 modal_btn receipt_close_btn">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `거래내역`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "TID", "총판", "에이전시", "대리점", "가맹점", "종류", "상태", "승인일자", "취소일자", "승인금액",
            "승인번호", "카드사", "할부개월", "구분"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.종류 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.상태 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.취소일자 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인금액 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.승인번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.카드사 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.할부개월 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Paylist